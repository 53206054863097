<!-- 课程类型 -->
<template>
  <div class="flex-col space-y-20 page">

    <div class="flex-col section_3">
      <div class="flex-col items-start group_3">
        <div class="flex-col items-start text-wrapper"><span class="text_2">员工证书管理</span></div>
      </div>
      <div class="flex-row justify-between group_4">
		<div class=" flex-row">
			
			<div class="flex-row justify-center">
				<span class="text_11">关键字</span>
				<el-input placeholder="请输入证书名称进行搜索" v-model="keyword" clearable class="text_12" style="width: 15rem;"></el-input>
				<div class="flex-col items-center text-wrapper_2" @click="searchBtn()"><span class="text_13">查询</span></div>
				<div class="flex-col items-center text-wrapper_3" @click="resetBtn()"><span class="text_14">重置</span></div>
			</div>
		</div>
		<div class="group_20">
		  <el-button type="danger"  @click="dialogVisible = true">添加员工证书</el-button>
		  <el-button type="primary"  @click="daochu">导出</el-button>
		</div>
	  </div>
      <div class="flex-col space-y-14 group_7" v-loading="loading" element-loading-text="数据正在加载中">
        <div v-if="list.length > 0">
          <el-table 
          ref="multipleTable"
          :data="list" 
          style="width: auto" 
          :header-cell-style="{background:'#eef0f7bf'}" 
          v-loading="loading" 
          element-loading-text = "数据正在加载中" 
          element-loading-spinner = "el-icon-loading"
		  row-key="id"
					:tree-props="{children: 'children', hasChildren: 'true'}">
                  <el-table-column :reserve-selection="true" type="selection" width="55" align="center"></el-table-column>
            <el-table-column type="index" label="序号" width="auto" align="center"></el-table-column>
                  <el-table-column prop="uname" label="姓名"  align="center"></el-table-column>
                  <el-table-column prop="major_name" label="证书名称"  align="center"></el-table-column>
                  <el-table-column label="专业"  align="center">
					  <template slot-scope="scope">
						  <span v-if="scope.row.major_name">{{scope.row.major_name2}}</span>
					  </template>
				  </el-table-column>
                  <el-table-column prop="receive_zscode_time" label="领证时间" width="auto" align="center">
					  <template slot-scope="scope">
						  <span :class="{ 'red-text': scope.row.receive_zscode_is_exp_soon == 1 }">{{ scope.row.receive_zscode_time }}</span>
					  </template>
                  </el-table-column>
				  <el-table-column prop="receive_education_time" label="继续教育时间" width="auto" align="center">
					  <template slot-scope="scope">
						  <span :class="{ 'red-text': scope.row.contract_is_exp_soon == 1 }">{{ scope.row.receive_education_time }}</span>
					  </template>
				  </el-table-column>
				  <el-table-column prop="contract_exp_time" label="合同到期时间" width="auto" align="center">
					  <template slot-scope="scope">
						  <span :class="{ 'red-text': scope.row.contract_is_exp_soon == 1 }">{{ scope.row.contract_exp_time }}</span>
					  </template>
				  </el-table-column>
                  <el-table-column
                        label="操作"
                        width="150">
                        <template slot-scope="scope">
						  <el-button @click="toLoginClick(scope.row)" type="text" size="small">做教育</el-button>
                          <el-button @click="editClick(scope.row)" type="text" size="small">编辑</el-button>
                          <el-button @click="detailClick(scope.row)" type="text" size="small">查看</el-button>
                          <el-button @click="delClick(scope.row)" type="text" size="small">删除</el-button>
                        </template>
				   </el-table-column>
		</el-table>
        </div>
        <div v-else>
          <div class="flex-col space-y-22 group_7">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
		
		<el-dialog
		  title="添加员工证书"
		  :visible.sync="dialogVisible"
		  width="30%"
		  @close="addDialogClose"
		  >
		  <el-form :model="addForm" ref="addForm" label-width="100px" class="demo-ruleForm">
		    <el-form-item label="姓名" prop="uid" :rules="[ { required: true, message: '请选择员工'}, ]">
		        <el-select v-model="addForm.uid" placeholder="请选择员工">
		          <el-option v-for="(item, index) in person" :label="item.name" :value="item.id"></el-option>
		        </el-select>
		    </el-form-item>
			<el-form-item label="证书名称" prop="cert_major_id1" :rules="[ { required: true, message: '请选择证书名称'}, ]">
			    <el-select v-model="addForm.cert_major_id1" @change="certMajorSelected" placeholder="请选择证书名称">
					<el-option v-for="(item, index) in major" :label="item.name" :value="item.id"></el-option>
			    </el-select>
			</el-form-item>
			<el-form-item label="证书专业" prop="cert_major_id2" :rules="[ { required: true, message: '请选择证书专业'}, ]">
			    <el-select v-model="addForm.cert_major_id2" placeholder="请选择证书专业">
					<el-option v-for="(item, index) in major2" :label="item.name" :value="item.id"></el-option>
			    </el-select>
				<!-- <el-cascader
					v-model="addForm.major_id"
				    placeholder="输入进行搜索"
				    :options="major"
					:props="{ label: 'name',value:'id',children:'child' }"
				    filterable></el-cascader> -->
			</el-form-item>
			
			
			  <el-form-item label="发证时间">
			      <el-col :span="22">
			        <el-form-item prop="receive_zscode_time" :rules="[ { required: true, message: '请选择发证时间'}, ]">
			          <el-date-picker type="date" placeholder="选择日期" v-model="addForm.receive_zscode_time" style="width: 100%;"></el-date-picker>
			        </el-form-item>
			      </el-col>
			    </el-form-item>
				<el-form-item label="继续教育时间">
				    <el-col :span="22">
				      <el-form-item prop="receive_education_time">
				        <el-date-picker type="date" placeholder="选择日期" v-model="addForm.receive_education_time" style="width: 100%;"></el-date-picker>
				      </el-form-item>
				    </el-col>
				  </el-form-item>
				  <el-form-item label="合同到期时间">
				      <el-col :span="22">
				        <el-form-item prop="contract_exp_time">
				          <el-date-picker type="date" placeholder="选择日期" v-model="addForm.contract_exp_time" style="width: 100%;"></el-date-picker>
				        </el-form-item>
				      </el-col>
				    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" @click="submitForm('addForm')">提交</el-button>
		      <el-button @click="dialogVisible = false">取消</el-button>
		    </el-form-item>
		  </el-form>
		</el-dialog>
		
		<!-- 编辑 -->
		<el-dialog
		  title="编辑员工证书"
		  :visible.sync="editDialogVisible"
		  width="30%"
		  @close="editDialogClose"
		  >
		  <el-form :model="editForm" ref="editForm" label-width="100px" class="demo-ruleForm">
		    <el-form-item label="员工" prop="uid" :rules="[ { required: true, message: '请选择员工'}, ]">
		        <el-select v-model="editForm.uid" placeholder="请选择员工">
		          <el-option v-for="(item, index) in person" :label="item.name" :value="item.id"></el-option>
		        </el-select>
		    </el-form-item>
			<el-form-item label="证书名称" prop="cert_major_id1" :rules="[ { required: true, message: '请选择证书名称'}, ]">
			    <el-select v-model="editForm.cert_major_id1" @change="certMajorSelected" placeholder="请选择证书名称">
					<el-option v-for="(item, index) in major" :label="item.name" :value="item.id"></el-option>
			    </el-select>
			</el-form-item>
			<el-form-item label="证书专业" prop="cert_major_id2" :rules="[ { required: true, message: '请选择证书专业'}, ]">
			    <el-select v-model="editForm.cert_major_id2" placeholder="请选择证书专业">
					<el-option v-for="(item, index) in major2" :label="item.name" :value="item.id"></el-option>
			    </el-select>
				<!-- <el-cascader
					v-model="editForm.major_id"
				    placeholder="输入进行搜索"
				    :options="major"
					:props="{ label: 'name',value:'id',children:'child' }"
				    filterable></el-cascader> -->
			</el-form-item>
			
			  <el-form-item label="发证时间">
			      <el-col :span="22">
			        <el-form-item prop="receive_zscode_time"  :rules="[ { required: true, message: '请选择发证时间'}, ]">
			          <el-date-picker type="date" placeholder="选择日期" v-model="editForm.receive_zscode_time" style="width: 100%;"></el-date-picker>
			        </el-form-item>
			      </el-col>
			    </el-form-item>
				<el-form-item label="继续教育时间">
				    <el-col :span="22">
				      <el-form-item prop="receive_education_time">
				        <el-date-picker type="date" placeholder="选择日期" v-model="editForm.receive_education_time" style="width: 100%;"></el-date-picker>
				      </el-form-item>
				    </el-col>
				  </el-form-item>
				  <el-form-item label="合同到期时间">
				      <el-col :span="22">
				        <el-form-item prop="contract_exp_time">
				          <el-date-picker type="date" placeholder="选择日期" v-model="editForm.contract_exp_time" style="width: 100%;"></el-date-picker>
				        </el-form-item>
				      </el-col>
				    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" @click="submitEditForm('editForm')">提交</el-button>
		      <el-button @click="editDialogVisible = false">取消</el-button>
		    </el-form-item>
		  </el-form>
		</el-dialog>
		<!-- 详情 -->
		<el-dialog
		  title="查看详情"
		  :visible.sync="detailShow"
		  width="30%"
		  >
			<el-descriptions :column="1">
				  <el-descriptions-item labelClassName="half-width" label="姓名">{{detailForm.uname}}</el-descriptions-item>
				  <el-descriptions-item labelClassName="half-width" label="证书名称">{{detailForm.name}}</el-descriptions-item>
				  <el-descriptions-item labelClassName="half-width" label="领证时间">{{detailForm.receive_zscode_time}}</el-descriptions-item>
				  <el-descriptions-item labelClassName="half-width" label="继续教育时间">{{detailForm.receive_education_time}}</el-descriptions-item>
				  <el-descriptions-item labelClassName="half-width" label="合同到期时间">{{detailForm.contract_exp_time}}</el-descriptions-item>
			</el-descriptions>
			<span slot="footer" class="dialog-footer">
				<el-button @click="detailShow = false">关闭</el-button>
			</span>
		</el-dialog>
      </div>
      <div class="page-box" v-if="list.length > 0">
        <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange" :page-sizes="[10, 20, 30, 40]" layout="sizes,prev, pager, next" :total="count"
          :page-size="limit">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import interfaces from '../../../../utils/interface.js'
  export default {
    components: {},
    data() {
      return {
		dialogVisible: false,
		editDialogVisible: false,
		detailDialogVisible: false,
		detailShow:false,
		person:[],
		major:[],
		major2:[],
		addForm: {
			uid: '',
			name: '',
			receive_zscode_time:'',
			receive_education_time:'',
			contract_exp_time:'',
			cert_major_id1:'',
			cert_major_id2:'',
		},
		editForm:{
			id:'',
			uid: '',
			name: '',
			receive_zscode_time:'',
			receive_education_time:'',
			contract_exp_time:'',
			cert_major_id1:'',
			cert_major_id2:'',
		},
		detailForm:{
			id:'',
			uname: '',
			name: '',
			major_name:'',
			receive_zscode_time:'',
			receive_education_time:'',
			contract_exp_time:'',
		},
		uploadImg: interfaces.uploadImages, //上传图片接口地址
		daochuurl:interfaces.daochuEnterprisePersonCer,//导出员工证书
        loading: false,
        page: 1,
        limit: 10,
        totalpage: 0,
        count: 0,
        curriculumCateArr: [],
        curriculum_cate_id: '',
        curriculum_id: '',
        trainTypeArr: [],
        train_type_id: '',
        status: '',
        keyword: '',
        list: [],
        tostud: '',//进入学习
        tostudId:'',//课程订单id
      };
    },
    created() {
      let that = this;
      that.getEnterprisePersonCer();
	  that.getEnterpriseYuangong();
	  that.getCertMajor();
    },
    methods: {
		//选择证书名称触发事件
		certMajorSelected(value){
			let that = this;
			that.addForm.cert_major_id2 = '';
			that.editForm.cert_major_id2 = '';
			let param = {
				pid:value,
			};
			that.getCertMajor2(param);
		},
		//关闭添加框
		addDialogClose(){
			let that = this;
			that.addForm.cert_major_id1 = '';
			that.addForm.cert_major_id2 = '';
		},
		//关闭编辑框
		editDialogClose(){
			let that = this;
			that.editForm.cert_major_id1 = '';
			that.editForm.cert_major_id2 = '';
		},
		handleSizeChange(val) {
			let that = this;
			that.page = 1;
			that.limit = val;
			that.getEnterprisePersonCer();
			// console.log(`每页 ${val} 条`);
	    },
		submitForm(formName) {
			let that = this;
			this.$refs[formName].validate((valid) => {
			  if (valid) {
				//调用接口添加资质
				that.addEnterprisePersonCer();
			  } else {
				console.log('error submit!!');
				return false;
			  }
			});
		},
		submitEditForm(formName) {
			let that = this;
			this.$refs[formName].validate((valid) => {
			  if (valid) {
				//调用接口添加资质
				that.editEnterprisePersonCer();
			  } else {
				console.log('error submit!!');
				return false;
			  }
			});
		},
		handleAvatarSuccess(res, file) {
			console.log(res);
			console.log(file);
			if(res.code == 200){
				this.addForm.imgpath = res.datas.location;
			}else{
				this.$message.error(res.msg);
			}
			
		},
		beforeAvatarUpload(file) {
			let that = this;
			const isImg = file.type === 'image/jpeg' || 'image/png';
			const isLt2M = file.size / 1024 / 1024 < 2;
	
			if (!isImg && !isPng) {
			  this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
			}
			if (!isLt2M) {
			  this.$message.error('上传图片大小不能超过 2MB!');
			}
			
			return isImg && isLt2M;
		},
		//获取员工证书列表
		getEnterprisePersonCer() {
		  let that = this;
		  //that.$store.commit('showLoading')
		  that.loading = true;
		  let param = {
		    page: that.page,
		    limit: that.limit,
		    curriculum_cate_id: that.curriculum_cate_id,
		    train_type_id: that.train_type_id,
		    keyword: that.keyword,
		  };
		  that.$request.getEnterprisePersonCer(param).then((res) => {
		    if (res.code == 200) {
		      that.list = res.datas;
		      if (that.page + 1 < res.total_page) {
		        that.page = that.page + 1;
		      }
		
		      that.totalpage = res.total_page;
		      that.count = res.count;
		      that.loading = false;
		    }
		  });
		},
		getEnterpriseYuangong(){
			let that = this;
			let param = {
			  page: 1,
			  limit: 99999,
			};
			that.$request.getEnterpriseYuangong(param).then((res) => {
			  if (res.code == 200) {
			    that.person = res.datas;
			  }
			});
		},
		getCertMajor(){
			let that = this;
			let param = {
				pid:0,
			};
			that.$request.getCertMajor(param).then((res) => {
			  if (res.code == 200) {
			    that.major = res.datas;
			  }
			});
		},
		//获取证书类型子类
		getCertMajor2(param){
			let that = this;
			that.$request.getCertMajor(param).then((res) => {
			  if (res.code == 200) {
			    that.major2 = res.datas;
			  }
			});
		},
		addEnterprisePersonCer() {
		  let that = this;
		  //that.$store.commit('showLoading')
		  that.addForm.receive_zscode_time = new Date(this.addForm.receive_zscode_time).getTime() / 1000;
		  that.addForm.receive_education_time = new Date(this.addForm.receive_education_time).getTime() / 1000;
		  that.addForm.contract_exp_time = new Date(this.addForm.contract_exp_time).getTime() / 1000;
		  that.loading = true;
		  let param = that.addForm;
		  //console.log(param);return false;
		  that.$request.addEnterprisePersonCer(param).then((res) => {
		    if (res.code == 200) {
				this.$message.success(res.msg);
				that.getEnterprisePersonCer();
				that.dialogVisible = false;
		    }else{
				this.$message.error(res.msg);
			}
		  });
		},
		editEnterprisePersonCer() {
		  let that = this;
		  //that.$store.commit('showLoading')
		  that.editForm.receive_zscode_time = new Date(this.editForm.receive_zscode_time).getTime() / 1000;
		  that.editForm.receive_education_time = new Date(this.editForm.receive_education_time).getTime() / 1000;
		  that.editForm.contract_exp_time = new Date(this.editForm.contract_exp_time).getTime() / 1000;
		  that.loading = true;
		  let param = that.editForm;
		  that.$request.editEnterprisePersonCer(param).then((res) => {
		    if (res.code == 200) {
				this.$message.success(res.msg);
				that.getEnterprisePersonCer();
				that.editDialogVisible = false;
		    }else{
				this.$message.error(res.msg);
			}
		  });
		},
      //点击页码
      handleCurrentChange(val) {
        let that = this;
        that.page = val;
        that.getEnterprisePersonCer();
      },
      //搜索
      searchBtn() {
        let that = this;
        that.page = 1;
        that.totalpage = 0;
        that.getEnterprisePersonCer();
      },
      //重置搜索
      resetBtn() {
        let that = this;
        that.page = 1;
        that.totalpage = 0;
        that.curriculum_cate_id = undefined;
        that.train_type_id = undefined;
        that.keyword = '';
        that.getEnterprisePersonCer();
      },
	  editClick(data){
		  let that = this;
		  that.editForm.id = data.id;
		  that.editForm.uid = data.uid;
		  that.editForm.name = data.name;
		  that.editForm.cert_major_id1 = data.cert_major_id1;
		  that.editForm.cert_major_id2 = data.cert_major_id2;
		  that.editForm.receive_zscode_time = data.receive_zscode_time;
		  that.editForm.receive_education_time = data.receive_education_time;
		  that.editForm.contract_exp_time = data.contract_exp_time;
		  that.editDialogVisible = true;
		  if(data.cert_major_id1){
			  let param = {
			  	pid:data.cert_major_id1,
			  };
			  that.getCertMajor2(param);
		  }
	  },
	  toLoginClick(data){
		  let that = this;
		  let id = data.uid;
		  console.log(id);
		  that.$confirm('确定登录学员账号吗？', '警告', {
		    confirmButtonText: '确定',
		    cancelButtonText: '取消',
		    type: 'warning'
		  }).then(() => {
			  let param = {
				  id:id,
			  };
			  that.$request.freeLogin(param).then((res) => {
				if (res.code == 200) {
					this.loading = true
					that.$store.commit('changeLoginState', 1);
					sessionStorage.setItem('isLogin', 1);
					sessionStorage.setItem('token', res.datas.token);
					sessionStorage.setItem('identity', res.datas.identity);
					that.$myMessage.success(res.msg);
					that.loading = true
					// that.$router.push({name:'Tuichudenglu_jiaohu'})
					setTimeout(function() {
						that.$router.push('/');
						location.reload();
					}, 300)
				}else{
					this.$message.error(res.msg);
				}
			  });
		    
		  }).catch(() => {
		    // 如果取消跳转地址栏会变化，这时保持地址栏不变
		    //window.history.go(1)
		  })
	  },
	  detailClick(data){
		  let that = this;
		  that.detailForm.id = data.id;
		  that.detailForm.uid = data.uid;
		  that.detailForm.uname = data.uname;
		  that.detailForm.name = data.name;
		  that.detailForm.cert_major_id1 = data.cert_major_id1;
		  that.detailForm.cert_major_id2 = data.cert_major_id2;
		  that.detailForm.major_name = data.major_name;
		  that.detailForm.receive_zscode_time = data.receive_zscode_time;
		  that.detailForm.receive_education_time = data.receive_education_time;
		  that.detailForm.contract_exp_time = data.contract_exp_time;
		  that.detailShow = true;
	  },
	  delClick(data){
		  let that = this;
		  // 这里需要elementui的支持，如果使用其他界面组件自行替换即可
		  that.$confirm('是否删除该条记录？', '警告', {
		    confirmButtonText: '确定',
		    cancelButtonText: '取消',
		    type: 'warning'
		  }).then(() => {
			  let param = {
				  id:data.id,
			  };
			  that.$request.delEnterprisePersonCer(param).then((res) => {
			    if (res.code == 200) {
					this.$message.success(res.msg);
					that.getEnterprisePersonCer();
					that.editDialogVisible = false;
			    }else{
					this.$message.error(res.msg);
				}
			  });
		    
		  }).catch(() => {
		    // 如果取消跳转地址栏会变化，这时保持地址栏不变
		    window.history.go(1)
		  })
	  },
	  daochu(){
		let that = this;
		this.$request.daochuEnterprisePersonCer({}).then((res)=>{ 
			console.log(res);
			this.downloadFile(res.data);
		})
	  },
	  downloadFile(data) {
		// 文件导出
		if (!data) {
			return
		}
		let url = window.URL.createObjectURL(new Blob([data]));
		let link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.setAttribute('download', '员工证书列表.xls');

		document.body.appendChild(link);
		link.click()
	}
    },
  };
</script>

<style scoped>
	/deep/.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  /deep/.avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .red-text {
	    color: red;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 178px;
	    height: 178px;
	    line-height: 178px;
	    text-align: center;
	  }
	  .avatar {
	    width: 178px;
	    height: 178px;
	    display: block;
	  }
	  /deep/.half-width {
	    width: 20%;
	  }
  .space-y-20>*:not(:first-child) {
    margin-top: 1.25rem;
  }

  .page {
    background-color: #f5f6fa;
    width: calc(100% - 220px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 1.25rem 1.25rem 1.88rem;
  }

  .page-box {
    margin: 1.65rem 0;
  }

  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.13rem;
    background-color: #ffffff;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }

  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .space-x-15>*:not(:first-child) {
    margin-left: 0.94rem;
  }

  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }

  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }

  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.88rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }

  .image_2 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }

  .section_3 {
    /* margin-right: 1.25rem; */
    padding: 0.94rem 0.94rem;
    align-self: auto;
    background-color: #ffffff;
    border-radius: 0.63rem;
    /* width: 71.56rem; */
  }

  .group_3 {
    margin-right: 0.31rem;
    overflow: hidden;
    border-bottom: solid 0.016rem #c0c4cc;
  }

  .text-wrapper {
    padding: 0.22rem 0 1.28rem;
    width: 7.85rem;
    border-bottom: solid 0.16rem #0074ff;
  }

  .text_2 {
    color: #0074ff;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .group_4 {
    margin-top: 1.69rem;
    /* align-self: flex-start; */
    overflow: hidden;
    /* width: 59.69rem; */
  }

  .space-x-8>*:not(:first-child) {
    margin-left: 0.5rem;
  }

  .group_5 {
    overflow: hidden;
    width: 12.63rem;
    /* height: 2.19rem; */
  }

  .text_3 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-45>*:not(:first-child) {
    margin-left: 2.81rem;
  }

  .section_4 {
    flex-shrink: 0;
    padding: 0.59rem 0.5rem 0.66rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }

  .text_4 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .image_6 {
    margin-right: 0.16rem;
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .group_6 {
    margin-left: 0.94rem;
    overflow: hidden;
    width: 10.69rem;
    height: 2.19rem;
  }

  .text_5 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-14>*:not(:first-child) {
    margin-left: 0.88rem;
  }

  .text_7 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_11 {
    margin-left: 1rem;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_1 {
    margin-left: 0.53rem;
    padding: 0.59rem 0 0.72rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }

  .text_12 {
    margin-left: 0.5rem;
    margin-right: 0.56rem;
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_2 {
    margin-left: 0.94rem;
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_13 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
    cursor: pointer;
  }

  .text-wrapper_3 {
    margin-left: 0.91rem;
    padding: 0.63rem 0 0.72rem;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
    cursor: pointer;
  }

  .text_14 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-y-14>*:not(:first-child) {
    margin-top: 0.88rem;
  }

  .group_7 {
    padding: 1.25rem 0.44rem 0;
  }

  .section_5 {
    margin-bottom: 1rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .section_6 {
    padding: 0.53rem 1.13rem 0.59rem;
    background-color: #fbfcff;
    border: solid 0.063rem #dcdfe6;
  }

  .text_15 {
    align-self: center;
    color: #9a9da7;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_4 {
    margin-right: 0.41rem;
    padding: 0.47rem 0 0.66rem;
    background-color: #fef0f0;
    border-radius: 0.31rem;
    width: 3.75rem;
    height: 1.88rem;
    border: solid 0.031rem #fde2e2;
  }

  .text_17 {
    color: #f56c6c;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .space-y-10>*:not(:first-child) {
    margin-top: 0.63rem;
  }

  .group_8 {
    margin: 0.94rem 1.53rem 0 1.16rem;
    overflow: hidden;
  }

  .text_19 {
    margin-bottom: 0.13rem;
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .space-x-6>*:not(:first-child) {
    margin-left: 0.38rem;
  }

  .group_10 {
    padding: 0.16rem 0 0.16rem 0.094rem;
    overflow: hidden;
    /* width: 8rem; */
    height: 1.25rem;
  }

  .text_21 {
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .text_23 {
    margin-top: 0.19rem;
    flex-shrink: 0;
    color: #ff5f5a;
    font-size: 1rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.66rem;
  }

  .space-x-10>*:not(:first-child) {
    margin-left: 0.63rem;
  }

  .group_11 {
    align-self: flex-start;
  }

  .text-wrapper_5 {
    flex: 1 1 auto;
    padding: 0.47rem 0 0.66rem;
    background-color: #ffffff;
    border-radius: 0.31rem;
    width: 4.25rem;
    height: 1.88rem;
    border: solid 0.031rem #b3d8ff;
  }

  .text_25 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .text-wrapper_6 {
    flex-shrink: 0;
    padding: 0.47rem 0 0.66rem;
    background-color: #ffffff;
    border-radius: 0.31rem;
    width: 2.75rem;
    height: 1.88rem;
    border: solid 0.031rem #b3d8ff;
  }

  .text_27 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .section_7 {
    margin-top: 0.88rem;
    padding: 0.41rem 1.13rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .text_29 {
    color: #7d7d8c;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_30 {
    align-self: center;
  }

  .group_12 {
    margin-right: 0.47rem;
  }

  .text-wrapper_7 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    color: #fff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text-wrapper_71 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_31 {
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_311 {
    color: #fff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_8 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_33 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_9 {
    margin-right: 0.41rem;
    padding: 0.47rem 0 0.66rem;
    background-color: #f4f4f5;
    border-radius: 0.31rem;
    width: 3.75rem;
    height: 1.88rem;
    border: solid 0.031rem #e9e9eb;
  }

  .text_36 {
    color: #909399;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .group_14 {
    align-self: flex-start;
  }

  .text-wrapper_10 {
    margin-top: 0.88rem;
    padding: 1.03rem 0 1.16rem;
    background-color: #ffffff;
    /* width: 68.75rem; */
    border: solid 0.063rem #dcdfe6;
  }

  .text_43 {
    margin-left: 1.13rem;
  }

  .group_16 {
    align-self: flex-start;
  }

  .group_18 {
    padding: 0.16rem 0;
    overflow: hidden;
    width: 8rem;
    height: 1.25rem;
  }

  .group_19 {
    align-self: flex-start;
  }

  .section_8 {
    margin-top: 0.88rem;
    padding: 0.41rem 1.13rem 0.41rem 1.13rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .text_60 {
    align-self: center;
  }

  .text-wrapper_11 {
    padding: 0.69rem 0 0.47rem;
    background-color: #f5f8ff;
    border-radius: 0.31rem 0.31rem 0 0;
    width: 5.63rem;
    height: 1.97rem;
  }

  .text-wrapper_12 {
    padding: 0.69rem 0 0.47rem;
    background-color: #0074ff;
    border-radius: 0.31rem 0.31rem 0 0;
    width: 5.63rem;
    height: 1.97rem;
  }
  
  
</style>
